form#popup {
    width: 40%;
    height: 75%;
    padding: 30px 50px 50px 50px;
    position: fixed;
    left: 32%;
    top: 16vh;
    background-color: white;
    visibility: hidden;
    border: 1px solid;
    box-shadow: "5px 10px #888888";
    z-index: 100;

    @media (max-width:1000px) {
        top: 18vh;
        height: 73%;
        width: 80%;
        left: 11%;
        z-index: 100;
    }

   button {
    position: relative;
    bottom: 55px;
    left: 90%;
    cursor: pointer;
    border: none;

    @media (max-width:1000px) {
        top: -13%;
        left: 96%;
    }
    }


    p{
        button{
            position: relative;
            left: 0px;
            top: -15px;
            
            @media (max-width:700px) {
                top: -10px;
            }
        }
    }
}